import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'getModule'})
export class GetModulePipe implements PipeTransform {
  transform(referenceModule: string, reference: string): string {
    let retVal: string = "";
    if(referenceModule && reference) {
      let module = "";
      if(referenceModule == 'TA') {
        module = "Task Assessment";
      } else if(referenceModule == 'PAI') {
        module = "Plant / Area Inspection";
      } else if(referenceModule == 'II') {
        module = "Incident Investigation";
      } else if(referenceModule == 'KPI') {
        module = "Key Performance Indicators";
      } else if(referenceModule == 'TRM') {
        module = "Task Risk Management";
      } else if(referenceModule == 'TP') {
        module = "Plan & Review";
      } else if(referenceModule == 'HR') {
        module = "Hazard Report";
      } else if(referenceModule == 'LI') {
        module = "Lead Indicator";
      }
      let name = referenceModule + '-' + reference.replace(/-/g, "").substring(0, 12).toUpperCase();

      retVal = module + " " + name;
    }

    return retVal;
  }
}