import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";

import { Storage } from "@ionic/storage";
import { RestService } from "src/app/common/services/rest.service";
import { ReportFilter } from "./report-filter";

import { AssessmentsByAssessorReport } from "./assessments/assessments-by-assessor-report";
import { AssessmentsByDepartmentReport } from "./assessments/assessments-by-department-report";
import { AssessmentsComplianceByCategoryReport } from "./assessments/assessments-compliance-by-category-report";
import { AssessmentsComplianceByElementReport } from "./assessments/assessments-compliance-by-element-report";
import { AssessmentsDetailedReport } from "./assessments/assessments-detailed-report";
import { AssessmentsElementMonthlyTrendReport } from "./assessments/assessments-element-monthly-trend-report";
import { AssessmentsRiskComplianceReport } from "./assessments/assessments-risk-compliance-report";
import { AssessmentsRiskRatingReport } from "./assessments/assessments-risk-rating-report";
import { AssessmentsRuleComplianceReport } from "./assessments/assessments-rule-compliance-report";
import { InspectionsByAssessorReport } from "./inspections/inspections-by-assessor-report";
import { InspectionsByDepartmentReport } from "./inspections/inspections-by-department-report";
import { InspectionsComplianceByCategoryReport } from "./inspections/inspections-compliance-by-category-report";
import { InspectionsComplianceByElementReport } from "./inspections/inspections-compliance-by-element-report";
import { InspectionsDetailedReport } from "./inspections/inspections-detailed-report";
import { InspectionsElementMonthlyTrendReport } from "./inspections/inspections-element-monthly-trend-report";
import { InspectionsRiskComplianceReport } from "./inspections/inspections-risk-compliance-report";
import { InspectionsRiskRatingReport } from "./inspections/inspections-risk-rating-report";
import { InspectionsRuleComplianceReport } from "./inspections/inspections-rule-compliance-report";
import { HazardsByDepartmentReport } from "./hazards/hazards-by-department-report";
import { HazardsComplianceByCategoryReport } from "./hazards/hazards-compliance-by-category-report";
import { HazardsComplianceByElementReport } from "./hazards/hazards-compliance-by-element-report";
import { HazardsDetailedReport } from "./hazards/hazards-detailed-report";
import { HazardsElementMonthlyTrendReport } from "./hazards/hazards-element-monthly-trend-report";
import { HazardsRiskComplianceReport } from "./hazards/hazards-risk-compliance-report";
import { HazardsRiskRatingReport } from "./hazards/hazards-risk-rating-report";
import { HazardsRuleComplianceReport } from "./hazards/hazards-rule-compliance-report";
import { HazardsByAssessorReport } from "./hazards/hazards-by-assessor-report";
import { IncidentsComplianceByCategoryReport as IncidentsComplianceByCategoryReport } from "./incidents/incidents-compliance-by-category-report";
import { IncidentsRiskComplianceReport } from "./incidents/incidents-risk-compliance-report";
import { IncidentsRuleComplianceReport } from "./incidents/incidents-rule-compliance-report";
import { IncidentsSystemAndOrganisationCausesReport } from "./incidents/incidents-system-and-organization-causes-report";
import { IncidentsImmediateCauseAnalysisReport } from "./incidents/incidents-immediate-cause-analysis-report";
import { IncidentsDetailedReport } from "./incidents/incidents-detailed-report";
import { ManagementsComplianceByCategoryReport } from "./managements/managements-compliance-by-category-report";
import { ManagementsComplianceByElementReport } from "./managements/managements-compliance-by-element-report";
import { CCMsComplianceByCategoryReport } from "./ccms/ccms-compliance-by-category-report";
import { CCMsComplianceByElementReport } from "./ccms/ccms-compliance-by-element-report";
import { CCMsRiskComplianceReport } from "./ccms/ccms-risk-compliance-report";
import { CCMsRuleComplianceReport } from "./ccms/ccms-rule-compliance-report";
import { IncidentsComplianceByElementReport } from "./incidents/incidents-compliance-by-element-report";
import { IndicatorsMonthlyPerformanceReport } from "./indicators/indicators-monthly-performance-report";
import { IndicatorsTrendReport } from "./indicators/indicators-trend-report";
import { PerformanceDashboardReport } from "./dashboard/performance-dashboard-report";
import { KPIsMonthlyReport } from "./kpis/kpis-monthly-report";
import { KPIsMonthByMonthTrend } from "./kpis/kpis-month-by-month-trend";
import { BBSDashboardReport } from "./dashboard/bbs-dashboard-report";
import { HazardsActsVsConditionsReport } from "./hazards/hazards-acts-vs-conditions-report";
import { environment } from "src/environments/environment";
import html2canvas from "html2canvas";
import { ChartType } from "../../model/detailReport.model";
import { FileService } from "../file/file.service";
import { Buffer } from "buffer";
import { LaggingDashboardReport } from "./dashboard/lagging-dashboard-report";
import { IncidentReportRawNumbersReport } from "./incident-report/incident-report-raw-numbers-report";
import { AppStateService } from "src/app/service/appState.service";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  downloadUrl: string = environment.bffUrl + "/extract/download";
  downloadUrlForEmail: string = environment.bffUrl + "/email/downloadPdf";
  // downloadUrl: string = environment.bffUrl + "/reportsDownload/download";

  constructor(
    private restService: RestService,
    private storage: Storage,
    private fileService: FileService,
    private appStateService: AppStateService
  ) {}

  getAssessmentsComplianceByElementReport(
    filter: ReportFilter
  ): Observable<AssessmentsComplianceByElementReport> {
    return this.restService
      .get(`/reports/assessments/compliance-by-element`, filter)
      .pipe(
        tap(async (res: AssessmentsComplianceByElementReport) => {
          return res;
        })
      );
  }

  getAssessmentsComplianceByCategoryReport(
    filter: ReportFilter
  ): Observable<AssessmentsComplianceByCategoryReport> {
    return this.restService
      .get(`/reports/assessments/compliance-by-category`, filter)
      .pipe(
        tap(async (res: AssessmentsComplianceByCategoryReport) => {
          return res;
        })
      );
  }

  getAssessmentsElementMonthlyTrendReport(
    filter: ReportFilter
  ): Observable<AssessmentsElementMonthlyTrendReport> {
    return this.restService
      .get(`/reports/assessments/element-monthly-trend`, filter)
      .pipe(
        tap(async (res: AssessmentsElementMonthlyTrendReport) => {
          return res;
        })
      );
  }

  getAssessmentsRiskRatingReport(
    filter: ReportFilter
  ): Observable<AssessmentsRiskRatingReport> {
    return this.restService
      .get(`/reports/assessments/risk-rating`, filter)
      .pipe(
        tap(async (res: AssessmentsRiskRatingReport) => {
          return res;
        })
      );
  }

  getAssessmentsRiskComplianceReport(
    filter: ReportFilter
  ): Observable<AssessmentsRiskComplianceReport> {
    return this.restService
      .get(`/reports/assessments/risk-compliance`, filter)
      .pipe(
        tap(async (res: AssessmentsRiskComplianceReport) => {
          return res;
        })
      );
  }

  getAssessmentsRuleComplianceReport(
    filter: ReportFilter
  ): Observable<AssessmentsRuleComplianceReport> {
    return this.restService
      .get(`/reports/assessments/rule-compliance`, filter)
      .pipe(
        tap(async (res: AssessmentsRuleComplianceReport) => {
          return res;
        })
      );
  }

  getAssessmentsByDepartmentReport(
    filter: ReportFilter
  ): Observable<AssessmentsByDepartmentReport> {
    return this.restService
      .get(`/reports/assessments/assessments-by-department`, filter)
      .pipe(
        tap(async (res: AssessmentsByDepartmentReport) => {
          return res;
        })
      );
  }

  getAssessmentsByAssessorReport(
    filter: ReportFilter
  ): Observable<AssessmentsByAssessorReport> {
    return this.restService
      .get(`/reports/assessments/assessments-by-assessor`, filter)
      .pipe(
        tap(async (res: AssessmentsByAssessorReport) => {
          return res;
        })
      );
  }

  getAssessmentsDetailedReport(
    filter: ReportFilter
  ): Observable<AssessmentsDetailedReport> {
    return this.restService
      .get(`/reports/assessments/detailed-report`, filter)
      .pipe(
        tap(async (res: AssessmentsDetailedReport) => {
          return res;
        })
      );
  }

  /**
   * Upload Reports, download report from s3 link , remove report pdf from s3
   * @param data
   * @returns
   */
  //for pdfkit
  generateReportHeaderLogo(data) {
    // return this.restService.post(`/reportsDownload/generateRepotHeaderLogo`, { data }).pipe(
    //   tap(async (res: AssessmentsDetailedReport) => {
    //     return res;
    //   })
    // );
    return this.restService
      .post(`/extract/generateRepotHeaderLogo`, { data })
      .pipe(
        tap(async (res: AssessmentsDetailedReport) => {
          return res;
        })
      );
  }
  generateReportImgs(data, captions) {
    return this.restService
      .post(`/extract/generateImgHandlerImages`, { data, captions })
      .pipe(
        tap(async (res: AssessmentsDetailedReport) => {
          return res;
        })
      );
  }
  //generateDetailReportImgs using img-handler
  generateDetailReportImgs(data) {
    return this.restService
      .post(`/extract/generateDetailReportImages`, { data })
      .pipe(
        tap(async (res: AssessmentsDetailedReport) => {
          return res;
        })
      );
  }
  // download photos for client > data
  photographBase64: { [key: string]: string } = {};
  photosDownload(data, assessmentId, type) {
    let photoLen = data.length;
    return new Promise((resolve, reject) => {
      data.forEach((photograph, i) => {
        let ext = photograph.substr(photograph.lastIndexOf(".") + 1);
        this.fileService
          .download("images", type, assessmentId, photograph)
          .subscribe(
            (arrayBuffer) => {
              let raw = Buffer.from(arrayBuffer).toString("base64");
              let base64 = "data:image/" + ext + ";base64," + raw;
              this.photographBase64[photograph] = base64;
              if (photoLen - 1 == i) {
                resolve(this.photographBase64);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    });
  }
  reportsDownload(data) {
    // return this.restService.post(`/reportsDownload`, data).pipe(
    //   tap(async (res: any) => {
    //     return res;
    //   })
    // );
    return this.restService
      .post(
        `/extract/generate`,
        data,
        {},
        { "Accept-Language": this.appStateService.defaultLanguage }
      )
      .pipe(
        tap(async (res: AssessmentsDetailedReport) => {
          return res;
        })
      );
  }
  removePdfReport(data) {
    // return this.restService.post(`/reportsDownload/removePdfReport`, { data }).pipe(
    //   tap(async (res) => {
    //     return res;
    //   })
    // );
    return this.restService.post(`/extract/removePdfReport`, { data }).pipe(
      tap(async (res) => {
        return res;
      })
    );
  }
  //download report pdf using pdfkit,aws s3 storage
  downloadReportPdf(key) {
    let url = `${this.downloadUrl}/${key}`;
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("href", url);
    document.body.appendChild(link);
    link.click();
    link.remove();

    this.removePdfReport(key).subscribe(() => {});
  }

  //download report pdf using pdfkit,aws s3 storage for EMAIL
  downloadReportPdfForEmail(key) {
    let url = `${this.downloadUrlForEmail}/${key}`;
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("href", url);
    document.body.appendChild(link);
    link.click();
    link.remove();

    // this.removePdfReport(key).subscribe(() => { })
  }
  //take screenshots of charts for reports
  screenshotForLineBarPieMixedChart(chartName) {
    return new Promise((resolve, reject) => {
      let ele = document.getElementById(chartName);
      let scale = 2;
      if (chartName == ChartType.PieChart) {
        scale = 1;
      }
      //setTimeout(() => {
      html2canvas(ele, {
        scale: 1,
        // width: window.innerWidth
      }).then((canvas) => {
        let img = null;
        img = canvas.toDataURL("image/png");
        resolve(img);
      });
      //  }, 1000);
    });
  }

  getAssessmentsActionReport(
    filter: ReportFilter
  ): Observable<AssessmentsDetailedReport> {
    return this.restService
      .get(`/reports/assessments/action-report`, filter)
      .pipe(
        tap(async (res: AssessmentsDetailedReport) => {
          return res;
        })
      );
  }

  getInspectionsComplianceByElementReport(
    filter: ReportFilter
  ): Observable<InspectionsComplianceByElementReport> {
    return this.restService
      .get(`/reports/inspections/compliance-by-element`, filter)
      .pipe(
        tap(async (res: InspectionsComplianceByElementReport) => {
          return res;
        })
      );
  }

  getInspectionsComplianceByCategoryReport(
    filter: ReportFilter
  ): Observable<InspectionsComplianceByCategoryReport> {
    return this.restService
      .get(`/reports/inspections/compliance-by-category`, filter)
      .pipe(
        tap(async (res: InspectionsComplianceByCategoryReport) => {
          return res;
        })
      );
  }

  getInspectionsElementMonthlyTrendReport(
    filter: ReportFilter
  ): Observable<InspectionsElementMonthlyTrendReport> {
    return this.restService
      .get(`/reports/inspections/element-monthly-trend`, filter)
      .pipe(
        tap(async (res: InspectionsElementMonthlyTrendReport) => {
          return res;
        })
      );
  }

  getInspectionsRiskRatingReport(
    filter: ReportFilter
  ): Observable<InspectionsRiskRatingReport> {
    return this.restService
      .get(`/reports/inspections/risk-rating`, filter)
      .pipe(
        tap(async (res: InspectionsRiskRatingReport) => {
          return res;
        })
      );
  }

  getInspectionsRiskComplianceReport(
    filter: ReportFilter
  ): Observable<InspectionsRiskComplianceReport> {
    return this.restService
      .get(`/reports/inspections/risk-compliance`, filter)
      .pipe(
        tap(async (res: InspectionsRiskComplianceReport) => {
          return res;
        })
      );
  }

  getInspectionsRuleComplianceReport(
    filter: ReportFilter
  ): Observable<InspectionsRuleComplianceReport> {
    return this.restService
      .get(`/reports/inspections/rule-compliance`, filter)
      .pipe(
        tap(async (res: InspectionsRuleComplianceReport) => {
          return res;
        })
      );
  }

  getInspectionsByDepartmentReport(
    filter: ReportFilter
  ): Observable<InspectionsByDepartmentReport> {
    return this.restService
      .get(`/reports/inspections/inspections-by-department`, filter)
      .pipe(
        tap(async (res: InspectionsByDepartmentReport) => {
          return res;
        })
      );
  }

  getInspectionsByAssessorReport(
    filter: ReportFilter
  ): Observable<InspectionsByAssessorReport> {
    return this.restService
      .get(`/reports/inspections/inspections-by-assessor`, filter)
      .pipe(
        tap(async (res: InspectionsByAssessorReport) => {
          return res;
        })
      );
  }

  getInspectionsDetailedReport(
    filter: ReportFilter
  ): Observable<InspectionsDetailedReport> {
    return this.restService
      .get(`/reports/inspections/detailed-report`, filter)
      .pipe(
        tap(async (res: InspectionsDetailedReport) => {
          return res;
        })
      );
  }

  getHazardsComplianceByElementReport(
    filter: ReportFilter
  ): Observable<HazardsComplianceByElementReport> {
    return this.restService
      .get(`/reports/hazards/compliance-by-element`, filter)
      .pipe(
        tap(async (res: HazardsComplianceByElementReport) => {
          return res;
        })
      );
  }

  getHazardsComplianceByCategoryReport(
    filter: ReportFilter
  ): Observable<HazardsComplianceByCategoryReport> {
    return this.restService
      .get(`/reports/hazards/compliance-by-category`, filter)
      .pipe(
        tap(async (res: HazardsComplianceByCategoryReport) => {
          return res;
        })
      );
  }

  getHazardsElementMonthlyTrendReport(
    filter: ReportFilter
  ): Observable<HazardsElementMonthlyTrendReport> {
    return this.restService
      .get(`/reports/hazards/element-monthly-trend`, filter)
      .pipe(
        tap(async (res: HazardsElementMonthlyTrendReport) => {
          return res;
        })
      );
  }

  getHazardsRiskRatingReport(
    filter: ReportFilter
  ): Observable<HazardsRiskRatingReport> {
    return this.restService.get(`/reports/hazards/risk-rating`, filter).pipe(
      tap(async (res: HazardsRiskRatingReport) => {
        return res;
      })
    );
  }

  getHazardsRiskComplianceReport(
    filter: ReportFilter
  ): Observable<HazardsRiskComplianceReport> {
    return this.restService
      .get(`/reports/hazards/risk-compliance`, filter)
      .pipe(
        tap(async (res: HazardsRiskComplianceReport) => {
          return res;
        })
      );
  }

  getHazardsRuleComplianceReport(
    filter: ReportFilter
  ): Observable<HazardsRuleComplianceReport> {
    return this.restService
      .get(`/reports/hazards/rule-compliance`, filter)
      .pipe(
        tap(async (res: HazardsRuleComplianceReport) => {
          return res;
        })
      );
  }

  getHazardsActsVsConditionsReport(
    filter: ReportFilter
  ): Observable<HazardsActsVsConditionsReport> {
    return this.restService
      .get(`/reports/hazards/acts-vs-conditions`, filter)
      .pipe(
        tap(async (res: HazardsActsVsConditionsReport) => {
          return res;
        })
      );
  }

  getHazardsByDepartmentReport(
    filter: ReportFilter
  ): Observable<HazardsByDepartmentReport> {
    return this.restService
      .get(`/reports/hazards/hazards-by-department`, filter)
      .pipe(
        tap(async (res: HazardsByDepartmentReport) => {
          return res;
        })
      );
  }

  getHazardsByAssessorReport(
    filter: ReportFilter
  ): Observable<HazardsByAssessorReport> {
    return this.restService
      .get(`/reports/hazards/hazards-by-assessor`, filter)
      .pipe(
        tap(async (res: HazardsByAssessorReport) => {
          return res;
        })
      );
  }

  getHazardsDetailedReport(
    filter: ReportFilter
  ): Observable<HazardsDetailedReport> {
    return this.restService
      .get(`/reports/hazards/detailed-report`, filter)
      .pipe(
        tap(async (res: HazardsDetailedReport) => {
          return res;
        })
      );
  }

  /** safe-act */

  getSafeActByDepartmentReport(
    filter: ReportFilter
  ): Observable<HazardsByDepartmentReport> {
    return this.restService
      .get(`/reports/safe-act/safe-act-by-department`, filter)
      .pipe(
        tap(async (res: HazardsByDepartmentReport) => {
          return res;
        })
      );
  }

  getSafeActByAssessorReport(
    filter: ReportFilter
  ): Observable<HazardsByAssessorReport> {
    return this.restService
      .get(`/reports/safe-act/safeAct-by-assessor`, filter)
      .pipe(
        tap(async (res: HazardsByAssessorReport) => {
          return res;
        })
      );
  }

  getSafeActRuleComplianceReport(
    filter: ReportFilter
  ): Observable<HazardsRuleComplianceReport> {
    return this.restService
      .get(`/reports/safe-act/behavioural-rule-compliance`, filter)
      .pipe(
        tap(async (res: HazardsRuleComplianceReport) => {
          return res;
        })
      );
  }

  getSafeActHighRiskReport(
    filter: ReportFilter
  ): Observable<HazardsRuleComplianceReport> {
    return this.restService
      .get(`/reports/safe-act/high-risk-compliance`, filter)
      .pipe(
        tap(async (res: HazardsRuleComplianceReport) => {
          return res;
        })
      );
  }

  getSafeActDetailedReport(
    filter: ReportFilter
  ): Observable<HazardsDetailedReport> {
    return this.restService
      .get(`/reports/safe-act/detialed-safe-act-report`, filter)
      .pipe(
        tap(async (res: HazardsDetailedReport) => {
          return res;
        })
      );
  }

  getIncidentsComplianceByCategoryReport(
    filter: ReportFilter
  ): Observable<IncidentsComplianceByCategoryReport> {
    return this.restService
      .get(`/reports/incidents/compliance-by-category`, filter)
      .pipe(
        tap(async (res: IncidentsComplianceByCategoryReport) => {
          return res;
        })
      );
  }

  getIncidentsComplianceByElementReport(
    filter: ReportFilter
  ): Observable<IncidentsComplianceByElementReport> {
    return this.restService
      .get(`/reports/incidents/compliance-by-element`, filter)
      .pipe(
        tap(async (res: IncidentsComplianceByElementReport) => {
          return res;
        })
      );
  }

  getIncidentsRiskComplianceReport(
    filter: ReportFilter
  ): Observable<IncidentsRiskComplianceReport> {
    return this.restService
      .get(`/reports/incidents/risk-compliance`, filter)
      .pipe(
        tap(async (res: IncidentsRiskComplianceReport) => {
          return res;
        })
      );
  }

  getIncidentsRuleComplianceReport(
    filter: ReportFilter
  ): Observable<IncidentsRuleComplianceReport> {
    return this.restService
      .get(`/reports/incidents/rule-compliance`, filter)
      .pipe(
        tap(async (res: IncidentsRuleComplianceReport) => {
          return res;
        })
      );
  }

  getIncidentsSystemAndOrganisationCausesReport(
    filter: ReportFilter
  ): Observable<IncidentsSystemAndOrganisationCausesReport> {
    return this.restService
      .get(`/reports/incidents/system-and-organization-causes`, filter)
      .pipe(
        tap(async (res: IncidentsSystemAndOrganisationCausesReport) => {
          return res;
        })
      );
  }

  getIncidentReportRawNumbersReport(
    filter: ReportFilter
  ): Observable<IncidentReportRawNumbersReport> {
    return this.restService
      .get(`/reports/incident-report/raw-numbers-report`, filter)
      .pipe(
        tap(async (res: IncidentReportRawNumbersReport) => {
          return res;
        })
      );
  }
  getIncidentReportLaggingFreqMonthly(
    filter: ReportFilter
  ): Observable<IncidentReportRawNumbersReport> {
    return this.restService
      .get(`/reports/incident-report/lagging-freq-monthly-report`, filter)
      .pipe(
        tap(async (res: IncidentReportRawNumbersReport) => {
          return res;
        })
      );
  }
  getIncidentReportLaggingFreqTrend(
    filter: ReportFilter
  ): Observable<IncidentReportRawNumbersReport> {
    return this.restService
      .get(`/reports/incident-report/lagging-freq-trend-report`, filter)
      .pipe(
        tap(async (res: IncidentReportRawNumbersReport) => {
          return res;
        })
      );
  }

  getIncidentReportDetailedReport(
    filter: ReportFilter
  ): Observable<IncidentReportRawNumbersReport> {
    return this.restService
      .get(`/reports/incident-report/detailed-report`, filter)
      .pipe(
        tap(async (res: IncidentReportRawNumbersReport) => {
          return res;
        })
      );
  }

  getIncidentsImmediateCauseAnalysisReport(
    filter: ReportFilter
  ): Observable<IncidentsImmediateCauseAnalysisReport> {
    return this.restService
      .get(`/reports/incidents/immediate-cause-analysis`, filter)
      .pipe(
        tap(async (res: IncidentsImmediateCauseAnalysisReport) => {
          return res;
        })
      );
  }

  getIncidentsDetailedReport(
    filter: ReportFilter
  ): Observable<IncidentsDetailedReport> {
    return this.restService
      .get(`/reports/incidents/detailed-report`, filter)
      .pipe(
        tap(async (res: IncidentsDetailedReport) => {
          return res;
        })
      );
  }

  getManagementsComplianceByElementReport(
    filter: ReportFilter
  ): Observable<ManagementsComplianceByElementReport> {
    return this.restService
      .get(`/reports/managements/compliance-by-element`, filter)
      .pipe(
        tap(async (res: ManagementsComplianceByElementReport) => {
          return res;
        })
      );
  }

  getManagementsComplianceByCategoryReport(
    filter: ReportFilter
  ): Observable<ManagementsComplianceByCategoryReport> {
    return this.restService
      .get(`/reports/managements/compliance-by-category`, filter)
      .pipe(
        tap(async (res: ManagementsComplianceByCategoryReport) => {
          return res;
        })
      );
  }

  getCCMsComplianceByCategoryReport(
    filter: ReportFilter
  ): Observable<CCMsComplianceByCategoryReport> {
    return this.restService
      .get(`/reports/ccms/compliance-by-category`, filter)
      .pipe(
        tap(async (res: CCMsComplianceByCategoryReport) => {
          return res;
        })
      );
  }

  getCCMsComplianceByElementReport(
    filter: ReportFilter
  ): Observable<CCMsComplianceByElementReport> {
    return this.restService
      .get(`/reports/ccms/compliance-by-element`, filter)
      .pipe(
        tap(async (res: CCMsComplianceByElementReport) => {
          return res;
        })
      );
  }

  getCCMsRiskComplianceReport(
    filter: ReportFilter
  ): Observable<CCMsRuleComplianceReport> {
    return this.restService.get(`/reports/ccms/risk-compliance`, filter).pipe(
      tap(async (res: CCMsRuleComplianceReport) => {
        return res;
      })
    );
  }

  getCCMsRuleComplianceReport(
    filter: ReportFilter
  ): Observable<CCMsRiskComplianceReport> {
    return this.restService.get(`/reports/ccms/rule-compliance`, filter).pipe(
      tap(async (res: CCMsRiskComplianceReport) => {
        return res;
      })
    );
  }

  getIndicatorsMonthlyPerformanceReport(
    filter: ReportFilter
  ): Observable<IndicatorsMonthlyPerformanceReport> {
    return this.restService
      .get(`/reports/indicators/monthly-performance-report`, filter)
      .pipe(
        tap(async (res: IndicatorsMonthlyPerformanceReport) => {
          return res;
        })
      );
  }

  getIndicatorsTrendReport(
    filter: ReportFilter
  ): Observable<IndicatorsTrendReport> {
    return this.restService
      .get(`/reports/indicators/trend-report`, filter)
      .pipe(
        tap(async (res: IndicatorsTrendReport) => {
          return res;
        })
      );
  }

  getKPIsMonthlyReport(filter: ReportFilter): Observable<KPIsMonthlyReport> {
    return this.restService.get(`/reports/kpis/monthly-report`, filter).pipe(
      tap(async (res: KPIsMonthlyReport) => {
        return res;
      })
    );
  }

  getKPIsMonthByMonthTrend(
    filter: ReportFilter
  ): Observable<KPIsMonthByMonthTrend> {
    return this.restService
      .get(`/reports/kpis/month-by-month-trend`, filter)
      .pipe(
        tap(async (res: KPIsMonthByMonthTrend) => {
          return res;
        })
      );
  }

  getPerformanceDashboardReport(
    filter: ReportFilter
  ): Observable<PerformanceDashboardReport> {
    return this.restService
      .get(`/reports/dashboard/performance-dashboard-report`, filter)
      .pipe(
        tap(async (res: PerformanceDashboardReport) => {
          return res;
        })
      );
  }

  getBBSDashboardReport(filter: ReportFilter): Observable<BBSDashboardReport> {
    return this.restService
      .get(`/reports/dashboard/bbs-dashboard-report`, filter)
      .pipe(
        tap(async (res: BBSDashboardReport) => {
          return res;
        })
      );
  }

  /** Lagging Dashboard */

  getLaggingDashboardReport(
    filter: ReportFilter
  ): Observable<LaggingDashboardReport> {
    return this.restService
      .get(`/reports/dashboard/lagging-dashboard-report`, filter)
      .pipe(
        tap(async (res: LaggingDashboardReport) => {
          return res;
        })
      );
  }

  // Checklists
  getChecklistsComplianceByCheckitemReport(
    filter: ReportFilter
  ): Observable<any> {
    return this.restService
      .get(`/reports/checklists/compliance-by-checkitem`, filter)
      .pipe(
        tap(async (res: any) => {
          return res;
        })
      );
  }

  getChecklistsRiskRatingReport(
    filter: ReportFilter
  ): Observable<any> {
    return this.restService
      .get(`/reports/checklists/risk-rating`, filter)
      .pipe(
        tap(async (res: any) => {
          return res;
        })
      );
  }

  getChecklistsByAssessorReport(
    filter: ReportFilter
  ): Observable<any> {
    return this.restService
      .get(`/reports/checklists/checklists-by-assessor`, filter)
      .pipe(
        tap(async (res: any) => {
          return res;
        })
      );
  }

  getChecklistsSafetyRuleReport(
    filter: ReportFilter
  ): Observable<any> {
    return this.restService
      .get(`/reports/checklists/safety-rules`, filter)
      .pipe(
        tap(async (res: any) => {
          return res;
        })
      );
  }

  getChecklistsCriticalRiskReport(
    filter: ReportFilter
  ): Observable<any> {
    return this.restService
      .get(`/reports/checklists/critical-risks`, filter)
      .pipe(
        tap(async (res: any) => {
          return res;
        })
      );
  }

  getChecklistsDetailedReport(
    filter: ReportFilter
  ): Observable<AssessmentsDetailedReport> {
    return this.restService
      .get(`/reports/checklists/detailed-report`, filter)
      .pipe(
        tap(async (res: AssessmentsDetailedReport) => {
          return res;
        })
      );
  }
}